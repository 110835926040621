<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';

export default {
  extends:GlobalVue,
  mounted(){
    this.refreshMeta()
  },
  methods:{
    refreshMeta(){
      Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
        if(err) return console.error(err)
        
        $("[rel=alternate]").remove()
        $("[rel=canonical]").remove()
        
        if(this.$route.name == "Index"){
          $("head").append('<link rel="canonical" href="https://www.sdi-alhidayah.sch.id">')
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        } 
        else if(this.$route.name && this.$route.name !== "404"){
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="id-id" />`)
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="x-default" />`)  
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        }
        else{
          $("[name='robots']").attr('content','noindex,nofollow')
          $("[name='googlebot']").attr('content','noindex,nofollow')
        }

        $("title").text(resp.meta_title)
        $("meta[name='keywords']").attr('content', resp.meta_keyword)
        $("meta[name='description']").attr('content', resp.meta_desc)
        $("meta[name='author']").attr('content', resp.ss_author)

        // OG
        $("meta[property='og:image']").attr('content', resp.ss_img)
        $("meta[property='og:title']").attr('content', resp.meta_title)
        $("meta[property='og:keywords']").attr('content', resp.meta_keyword)
        $("meta[property='og:description']").attr('content', resp.meta_desc)
        $("meta[property='og:url']").attr('content', resp.ss_url)

        // Twitter
        $("meta[name='twitter:image']").attr('content', resp.ss_img)
        $("meta[name='twitter:title']").attr('content', resp.meta_title)
        $("meta[name='twitter:description']").attr('content', resp.meta_desc)
        $("meta[name='twitter:url']").attr('content', resp.ss_url)
        this.$root.meta = resp
      })
    },
    dateStrFormat(date,type="a"){
      let listMonth = []
      if(type == "a"){
        listMonth = [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember"
        ]
      }
      else if(type == "b"){
        listMonth = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agt",
          "Sep",
          "Okt",
          "Nov",
          "Des"
        ]
      }
      let splitdate = date.split(/[\s-]/)
      let day = splitdate[2]
      let month = listMonth[parseInt(splitdate[1]) - 1]
      let year = splitdate[0]
      
      let dateFormat = [day, month, year].join(' ')
      return dateFormat
    },
    dateIdFormat(date){
      if(date){
        let listMonth = [
         "Januari",
         "Februari",
         "Maret",
         "April",
         "Mei",
         "Juni",
         "Juli",
         "Agustus",
         "September",
         "Oktober",
         "November",
         "Desember"
       ]
       let day = ('0' + date.getDate()).slice(-2)
       let month = listMonth[date.getMonth()]
       let year = date.getFullYear()
       let dateFormat = [day, month, year].join(' ')
       return dateFormat
      }
    }
  },
  watch:{
    $route(){
      this.refreshMeta()
    }
  }
}

</script>
