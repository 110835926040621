<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import {
  CalendarIcon,
  PhoneIcon,
  AtSignIcon,
  ChevronDownIcon,
  EyeIcon,
} from "vue-feather-icons";
import Gen from "../helper/Gen";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  extends: BaseVue,
  data() {
    return {
      isLoading: false,
      disabled: false,
      input: {
        sakta: "",
        sphoto: "",
        skk: "",
        stk_ijazah: "",
        stk_psikotest: "",
        sclass_register: "",
        sreligion: "",
        sgender: "",
        mreligion: "",
        preligion: "",
        freligion: "",
        mlast_education: "",
        plast_education: "",
        flast_education: "",
        fjob: "",
        mjob: "",
        pjob: "",
        mjob_address: "",
        fjob_address: "",
        pjob_address: "",
        addTK: false,
        addFather: false,
        addPic: false,
        aggreTerms: false,
        ppdbactive: false,
      },
      cluster: {},
      static1_desc: "",
      static2_desc: "",
      static3_desc: "",
    };
  },
  components: {
    PhoneIcon,
    CalendarIcon,
    AtSignIcon,
    ChevronDownIcon,
    DatePicker,
    EyeIcon,
  },
  async mounted() {
    this.refreshMeta()
    this.getPpdb()
    this.getClusterRegister();
    await this.ready();
    setTimeout(() => {
      SEMICOLON.widget.toggles();
    }, 500);
    setTimeout(() => {
      $(".popimage").magnificPopup({
        type: "image",
      });
    }, 1000);
    $(document).ready(() => {
      $("#processTabs").tabs({
        show: {
          effect: "fade",
          duration: 400,
        },
      });
      $(".tab-linker").click(function() {
        $("#processTabs").tabs("option", "active", $(this).attr("rel") - 1);
        $("#tab1").css("background-color", "#dcdcdc");
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: 0,
          },
          500
        );
        return false;
      });
      $("#dataTkCheck").change(function() {
        if ($(this).prop("checked") == true) {
          $("#dataTk").removeClass("d-none");
        } else {
          $("#dataTk").addClass("d-none");
        }
      });
      $("#customCheckAyah").change(function() {
        if ($(this).prop("checked") == true) {
          $("#dataAyah").removeClass("d-none");
        } else {
          $("#dataAyah").addClass("d-none");
        }
      });
      $("#customCheckWali").change(function() {
        if ($(this).prop("checked") == true) {
          $("#dataWali").removeClass("d-none");
        } else {
          $("#dataWali").addClass("d-none");
        }
      });
    });
  },
  methods: {
    refreshMeta() {
      Gen.rest('/registrasi', { metainfo: true }, (err, resp) => {
        if (err) return console.error(err);

        $("[rel=alternate]").remove()
        $("[rel=canonical]").remove()
        
        if(this.$route.name == "Index"){
          $("head").append('<link rel="canonical" href="https://www.sdi-alhidayah.sch.id">')
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        } 
        else if(this.$route.name && this.$route.name !== "404"){
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="id-id" />`)
          $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="x-default" />`)  
          $("[name='robots']").attr('content','index,follow')
          $("[name='googlebot']").attr('content','index,follow')
        }
        else{
          $("[name='robots']").attr('content','noindex,nofollow')
          $("[name='googlebot']").attr('content','noindex,nofollow')
        }
        
        $("title").text(resp.meta_title);
        $("meta[name='keywords']").attr('content', resp.meta_keyword)
        $("meta[name='description']").attr('content', resp.meta_desc)
        $("meta[name='author']").attr("content", 'SDI Al Hidayah');

        // OG
        $("meta[property='og:image']").attr('content', resp.ss_img)
        $("meta[property='og:title']").attr('content', resp.meta_title)
        $("meta[property='og:keywords']").attr('content', resp.meta_keyword)
        $("meta[property='og:description']").attr('content', resp.meta_desc)
        $("meta[property='og:url']").attr('content', resp.ss_url)
        
        // Twitter
        $("meta[name='twitter:image']").attr('content', resp.ss_img)
        $("meta[name='twitter:title']").attr('content', resp.meta_title)
        $("meta[name='twitter:description']").attr('content', resp.meta_desc)
        $("meta[name='twitter:url']").attr('content', resp.ss_url)
        this.$root.meta = resp;
      });
    },
    getPpdb() {
      Gen.apirest("/header", {}, (err, resp) => {
        if (err) console.log("Header " + err);
        this.ppdbactive = resp.result;
        if(!this.ppdbactive) this.$router.push({name : 'Index'})
      });
    },
    getClusterRegister() {
      Gen.apirest("/ppdb", {}, (err, resp) => {
        this.cluster = resp.cluster;
        this.static1_desc = resp.static1_desc;
        this.static2_desc = resp.static2_desc;
        this.static3_desc = resp.static3_desc;
        this.input.acr_id = resp.cluster.acr_id;
      });
    },
    submitPPDB() {
      this.$refs["VForm"].validate().then((success) => {
        if (!success) {
          return;
        }
        this.disabled = true;
        this.isLoading = true;
        Gen.apirest(
          "/ppdb",
          this.input,
          (err, resp) => {
            if (err) {
              this.disabled = false;
              this.isLoading = false;
              if (err.status == 422) {
                Object.keys(err.responseJSON).forEach((k) => {
                  var textError = err.responseJSON[k][0];
                  if (textError == "validation.required")
                    textError = "Input Required";
                  $("[error=" + k + "]").html(
                    "<label class='text-danger'>" + textError + "</label>"
                  );
                });
              } else {
                Gen.info("Ups ada kesalahan terjadi", "danger", 3000, ".info");
              }
              return;
            }
            this.input = {
              sakta: "",
              sphoto: "",
              skk: "",
              stk_ijazah: "",
              stk_psikotest: "",
              sclass_register: "",
              sreligion: "",
              mreligion: "",
              preligion: "",
              freligion: "",
              mlast_education: "",
              plast_education: "",
              flast_education: "",
              fjob: "",
              mjob: "",
              pjob: "",
              addTK: false,
              addFather: false,
              addPic: false,
              aggreTerms: false,
            };
            this.$nextTick(() => {
              this.$refs["VForm"].reset();
            });
            this.$router.push({
              name: "ThankYou",
              query: { code: resp.code_registration },
            });
            this.disabled = false;
            this.isLoading = false;
          },
          "POST"
        );
      });
    },
    uploadFile(e, key) {
      $(`[for='${e.target.id}']`).html(`
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          style="width: 2rem; height: 2rem;"
        ></span>
        <span class="sr-only">Loading...</span>
      `);
      var files = e.target.files || e.dataTransfer.files;
      var formData = new FormData();
      if (!files.length) return;
      var image = files[0];
      formData.append("pageType", "ppdb");
      formData.append("file", image);
      $.ajax({
        url: this.baseUrl + "/ajax/upload",
        type: "POST",
        data: formData,
        enctype: "multipart/form-data",
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        success: (data, status, xhr) => {
          $(`[for='${e.target.id}']`).html('UPDATE');
          if (data == "file not allowed") {
            $("[error=" + key + "]").html(
              "<label class='text-danger'>Allowed formats are : JPG, PNG, JPEG</label>"
            );
            this.input[key] = false;
          } else {
            this.input[key] = data.pathfile;
            setTimeout(() => {
              $(".popimage").magnificPopup({
                type: "image",
              });
            }, 200);
            $("[error=" + key + "]").html(
              "<label class='text-danger'></label>"
            );
          }
        },
      });
    },
    checkValidate2() {
      this.$refs["Section2"].validate().then((success) => {
        if (
          !success ||
          !this.input.sakta ||
          !this.input.skk ||
          !this.input.sphoto
        ) {
          Object.keys({
            sakta: this.input.sakta,
            skk: this.input.skk,
            sphoto: this.input.sphoto,
          }).forEach((k) => {
            if (!this.input[k]) {
              $("[error=" + k + "]").html(
                "<label class='text-danger'>This field is required</label>"
              );
            }
          });
          return;
        }
        $("#processTabs").tabs("option", "active", 2);
        $("#tab2").css("background-color", "#dcdcdc");
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: 0
          },
          500
        );
      });
    },
  },
  watch: {
    $route(){
      this.getPpdb()
      this.refreshMeta()
    }
  }
};
</script>
<template>
  <section id="content">
    <div class="content-wrap">
      <section class="section py-sm">
        <div class="container">
          <div class="form-body">
            <div id="processTabs">
              <ul class="stepper process-steps">
                <li>
                  <a href="#ptab1" id="tab1" class="stepper-point">1</a>
                  <span itemprop="name" class="stepper-title">Informasi</span>
                </li>
                <li>
                  <a href="#ptab2" id="tab2" class="stepper-point">2</a>
                  <span itemprop="name" class="stepper-title">Data Murid</span>
                </li>
                <li>
                  <a href="#ptab3" class="stepper-point">3</a>
                  <span itemprop="name" class="stepper-title">Data Orang Tua</span>
                </li>
              </ul>

              <div class="info"></div>

              <validation-observer v-slot="{ handleSubmit }" ref="Section1">
                <form @submit.prevent="handleSubmit(checkValidate1)">
                  <div id="ptab1">
                    <div class="row justify-content-center">
                      <div class="col-lg-8 col-md-10">
                        <div class="form-body-content">
                          <h2 itemprop="alternativeHeadline" class="form-welcome__title">Selamat Datang!</h2>
                          <div class="form-welcome-content">
                            <p itemprop="description">
                              SD Islam Al Hidayah, sekolah dasar yang menanamkan
                              keyakinan, moral, dan ilmu pengetahuan berdasarkan
                              nilai-nilai islam dengan melibatkan interaksi
                              timbal balik antara murid dan pengajar agar
                              tercipta suasana belajar yang efektif dan
                              menyenangkan.
                            </p>
                            <div class="toggle form-welcome-toggle">
                              <div
                                class="toggle-header form-welcome-toggle__header"
                              >
                                <div itemprop="name" class="toggle-title">
                                  Persyaratan dan Dokumen yang Diperlukan
                                </div>
                                <div class="toggle-icon">
                                  <chevron-down-icon></chevron-down-icon>
                                </div>
                              </div>
                              <div
                                itemprop="description"
                                class="toggle-content form-welcome-toggle__content"
                                v-html="static1_desc.as_val"
                              ></div>
                            </div>

                            <div class="toggle form-welcome-toggle">
                              <div
                                class="toggle-header form-welcome-toggle__header"
                              >
                                <div itemprop="name" class="toggle-title">
                                  Tata Cara Pendaftaran
                                </div>
                                <div class="toggle-icon">
                                  <chevron-down-icon></chevron-down-icon>
                                </div>
                              </div>
                              <div
                                itemprop="description"
                                class="toggle-content form-welcome-toggle__content"
                                v-html="static2_desc.as_val"
                              >
                              </div>
                            </div>

                            <div class="toggle form-welcome-toggle">
                              <div
                                class="toggle-header form-welcome-toggle__header"
                              >
                                <div itemprop="name" class="toggle-title">
                                  Waktu Pembukaan Pendaftaran Online
                                </div>
                                <div class="toggle-icon">
                                  <chevron-down-icon></chevron-down-icon>
                                </div>
                              </div>
                              <div
                                itemprop="description"
                                class="toggle-content form-welcome-toggle__content"
                                v-html="static3_desc.as_val"
                              >
                              </div>
                            </div>

                            <p itemprop="description">
                              Silakan persiapkan dokumen yang diperlukan dan
                              klik tombol <strong>Selanjutnya</strong> untuk
                              mulai melakukan pendaftaran.
                            </p>
                          </div>
                          <div class="card-elem">
                            <div class="registration">
                              <h3 itemprop="alternativeHeadline" class="registration__title">
                                Siapkan Dokumen Berikut:
                              </h3>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck1"
                                  v-model="input.checkAkta"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck1"
                                  itemprop="name"
                                  >Akta Kelahiran</label
                                >
                              </div>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck2"
                                  v-model="input.checkPhoto"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck2"
                                  itemprop="name"
                                  >Pas Foto 3x4</label
                                >
                              </div>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck3"
                                  v-model="input.checkKK"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck3"
                                  itemprop="name"
                                  >Kartu Keluarga</label
                                >
                              </div>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck4"
                                  v-model="input.checkIjazah"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customCheck4"
                                  itemprop="name"
                                  >Ijazah TK (Opsional)</label
                                >
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end">
                            <button
                              type="submit"
                              class="cta cta--primary tab-linker mb-3 mb-md-0"
                              :class="
                                input.checkPhoto &&
                                input.checkAkta &&
                                input.checkKK
                                  ? ''
                                  : 'cta--disabled'
                              "
                              rel="2"
                            >
                              Selanjutnya <i class="icon-line-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </validation-observer>
              <validation-observer v-slot="{ handleSubmit }" ref="Section2">
                <form @submit.prevent="handleSubmit(checkValidate2)">
                  <div id="ptab2">
                    <div class="row justify-content-center">
                      <div class="col-lg-8 col-md-10">
                        <div class="form-body-content">
                          <div class="card-elem">
                            <div class="registration">
                              <h3 itemprop="alternativeHeadline" class="registration__title">Data Murid</h3>

                              <div class="row gutter-16 col-mb-0">
                                <div class="col-12">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="sname"
                                    rules="required|alpha_spaces|max:100|min:3"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="nama">Nama</label>
                                      <input
                                        type="text"
                                        placeholder="e.g. Ahmad Wijayanto"
                                        class="form-control"
                                        v-model="input.sname"
                                        id="nama"
                                      />
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-12"></div>
                                <div class="col-md-6">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="splace_of_birth"
                                    rules="required|alpha_spaces"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="tempatLahir">Tempat Lahir</label>

                                      <input
                                        type="text"
                                        placeholder="e.g. Bogor"
                                        class="form-control"
                                        v-model="input.splace_of_birth"
                                        id="tempatLahir"
                                      />
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-6">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="sdate_of_birth"
                                    rules="required"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="tglLahir">Tanggal Lahir</label>
                                      <div class="input-group">
                                        <date-picker
                                          id="tglLahir"
                                          placeholder="DD-MM-YYYY"
                                          v-model="input.sdate_of_birth"
                                          type="date"
                                          format="DD-MM-YYYY"
                                          input-class="form-control"
                                          :default-value="new Date((cluster.year - 5), (cluster.month - 1), cluster.day).toString()"
                                          :disabled-date="(date) => date > new Date((cluster.year - 5), (cluster.month - 1), cluster.day)"
                                        ></date-picker>
                                        <div class="input-group-append">
                                          <span class="input-group-text"
                                            ><calendar-icon></calendar-icon
                                          ></span>
                                        </div>
                                      </div>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="jenisKelamin">Jenis Kelamin</label>
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sgender"
                                      rules="required"
                                      id="jenisKelamin"
                                    >
                                      <select
                                        name=""
                                        id=""
                                        class="custom-select form-control"
                                        v-model="input.sgender"
                                      >
                                        <option selected value="" hidden
                                          >-- Pilih Jenis Kelamin --</option
                                        >
                                        <option value="Laki-laki"
                                          >Laki-laki</option
                                        >
                                        <option value="Perempuan"
                                          >Perempuan</option
                                        >
                                      </select>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="saddress"
                                    rules="required"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="alamat">Alamat</label>
                                      <textarea
                                        name=""
                                        id="alamat"
                                        rows="5"
                                        placeholder="e.g. Jl. Pahlawan No 50B, Tangerang Selatan"
                                        class="form-control"
                                        v-model="input.saddress"
                                      ></textarea>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="mendaftarKelas">Mendaftar Kelas</label>
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sclass_register"
                                      rules="required"
                                    >
                                      <select
                                        name="mendaftar"
                                        id="mendaftarKelas"
                                        class="custom-select form-control"
                                        v-model="input.sclass_register"
                                      >
                                        <option selected value="" hidden
                                          >-- Pilih Kelas --</option
                                        >
                                        <option value="Satu">Satu</option>
                                        <option value="Dua">Dua</option>
                                        <option value="Tiga">Tiga</option>
                                        <option value="Empat">Empat</option>
                                        <option value="Lima">Lima</option>
                                        <option value="Enam">Enam</option>
                                      </select>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="agama">Agama</label>
                                    <validation-provider
                                      #default="{errors}"
                                      rules="required"
                                      name="sreligion"
                                    >
                                      <select
                                        name=""
                                        id="agama"
                                        class="custom-select form-control"
                                        v-model="input.sreligion"
                                      >
                                        <option value="" selected hidden
                                          >-- Pilih Agama --</option
                                        >
                                        <option value="Islam">Islam</option>
                                        <option value="Kristen">Kristen</option>
                                        <option value="Konghucu"
                                          >Konghucu</option
                                        >
                                        <option value="Buddha">Buddha</option>
                                        <option value="Lainnya">Lainnya</option>
                                      </select>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="status">Status dalam Keluarga</label>
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sfamily_status"
                                      rules="required|alpha_spaces|"
                                    >
                                      <input
                                        id="status"
                                        type="text"
                                        placeholder="e.g. Anak Kandung"
                                        class="form-control"
                                        v-model="input.sfamily_status"
                                      />
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="anakKe">Anak ke-</label>
                                    <validation-provider
                                      #default="{errors}"
                                      name="snth_child"
                                      rules="required|alpha_num"
                                    >
                                      <input
                                        id="anakKe"
                                        type="text"
                                        placeholder="e.g. Satu"
                                        class="form-control"
                                        v-model="input.snth_child"
                                      />
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                  <div class="form-group mb-0">
                                    <label itemprop="name">Akta Kelahiran</label>
                                    <div class="uploader">
                                      <a
                                        v-if="input.sakta"
                                        :href="uploader(input.sakta)"
                                        class="uploader__preview mr-1 popimage view-lightbox"
                                        ><eye-icon></eye-icon
                                      ></a>
                                      <label
                                        itemprop="name"
                                        for="akta"
                                        href="#"
                                        class="uploader__btn"
                                        >{{
                                          input.sakta ? "UPDATE" : "UPLOAD"
                                        }}</label
                                      >
                                      <input
                                        type="file"
                                        name="akta"
                                        id="akta"
                                        class="uploader__input"
                                        accept="image/jpeg, image/jpg, image/png"
                                        @change="uploadFile($event, 'sakta')"
                                      />
                                    </div>
                                    <span error="sakta"></span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                  <div class="form-group mb-0">
                                    <label itemprop="name">Pas Foto 3x4</label>
                                    <div class="uploader">
                                      <a
                                        v-if="input.sphoto"
                                        :href="uploader(input.sphoto)"
                                        class="uploader__preview mr-1 popimage view-lightbox"
                                        ><eye-icon></eye-icon
                                      ></a>
                                      <label
                                        itemprop="name"
                                        for="pasFoto"
                                        href="#"
                                        class="uploader__btn"
                                        >{{
                                          input.sphoto ? "UPDATE" : "UPLOAD"
                                        }}</label
                                      >
                                      <input
                                        type="file"
                                        name="pasFoto"
                                        id="pasFoto"
                                        class="uploader__input"
                                        accept="image/jpeg, image/jpg, image/png"
                                        @change="uploadFile($event, 'sphoto')"
                                      />
                                    </div>
                                    <span error="sphoto"></span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                  <div class="form-group mb-0">
                                    <label itemprop="name">Kartu Keluarga</label>
                                    <div class="uploader">
                                      <a
                                        v-if="input.skk"
                                        :href="uploader(input.skk)"
                                        class="uploader__preview mr-1 popimage view-lightbox"
                                        ><eye-icon></eye-icon
                                      ></a>
                                      <label
                                        itemprop="name"
                                        for="kartuKeluarga"
                                        href="#"
                                        class="uploader__btn"
                                        >{{
                                          input.skk ? "UPDATE" : "UPLOAD"
                                        }}</label
                                      >
                                      <input
                                        type="file"
                                        name="kartuKeluarga"
                                        id="kartuKeluarga"
                                        class="uploader__input"
                                        accept="image/jpeg, image/jpg, image/png"
                                        @change="uploadFile($event, 'skk')"
                                      />
                                      <span error="skk"></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="dataTkCheck"
                                        v-model="input.addTK"
                                      />
                                      <label
                                        itemprop="name"
                                        class="custom-control-label"
                                        for="dataTkCheck"
                                        >Tambahkan Data TK?</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 d-none" id="dataTk">
                                  <div class="row gutter-16 col-mb-0">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label itemprop="name" for="asalTk">Asal TK</label>
                                        <validation-provider
                                          #default="{ errors }"
                                          name="stk"
                                          :rules="input.addTK ? 'required' : ''"
                                        >
                                          <input
                                            id="asalTk"
                                            type="text"
                                            placeholder="e.g. TK Jayabaya"
                                            class="form-control"
                                            v-model="input.stk"
                                          />
                                          <span class="text-danger">{{
                                            errors[0]
                                          }}</span>
                                        </validation-provider>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                      <div class="form-group">
                                        <label itemprop="name">Ijazah TK</label>
                                        <div class="uploader">
                                          <a
                                            v-if="input.stk_ijazah"
                                            :href="uploader(input.stk_ijazah)"
                                            class="uploader__preview mr-1 popimage view-lightbox"
                                            ><eye-icon></eye-icon
                                          ></a>
                                          <label
                                            itemprop="name"
                                            for="ijazahTk"
                                            href="#"
                                            class="uploader__btn"
                                            >{{
                                              input.stk_ijazah
                                                ? "UPDATE"
                                                : "UPLOAD"
                                            }}</label
                                          >
                                          <input
                                            type="file"
                                            name="ijazahTk"
                                            id="ijazahTk"
                                            class="uploader__input"
                                            accept="image/jpeg, image/jpg, image/png"
                                            @change="
                                              uploadFile($event, 'stk_ijazah')
                                            "
                                          />
                                          <span error="stk_ijazah"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                      <div class="form-group">
                                        <label itemprop="name">Hasil Psikotes</label>
                                        <div class="uploader">
                                          <a
                                            v-if="input.stk_psikotest"
                                            :href="
                                              uploader(input.stk_psikotest)
                                            "
                                            class="uploader__preview mr-1 popimage view-lightbox"
                                            ><eye-icon></eye-icon
                                          ></a>
                                          <label
                                            itemprop="name"
                                            for="psychoTest"
                                            href="#"
                                            class="uploader__btn"
                                            >{{
                                              input.stk_psikotest
                                                ? "UPDATE"
                                                : "UPLOAD"
                                            }}</label
                                          >
                                          <input
                                            type="file"
                                            name="psychoTest"
                                            id="psychoTest"
                                            class="uploader__input"
                                            accept="image/jpeg, image/jpg, image/png"
                                            @change="
                                              uploadFile(
                                                $event,
                                                'stk_psikotest'
                                              )
                                            "
                                          />
                                          <span error="stk_psikotest"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex flex-column-reverse flex-md-row justify-content-between text-center"
                        >
                          <a href="#" class="tab-linker" rel="1"
                            ><i class="icon-line-arrow-left"></i> Sebelumnya</a
                          >
                          <button
                            class="cta cta--primary mb-3 mb-md-0"
                            rel="3"
                            type="submit"
                          >
                            Selanjutnya <i class="icon-line-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </validation-observer>
              <validation-observer v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(submitPPDB)">
                  <div id="ptab3">
                    <div class="row justify-content-center">
                      <div class="col-lg-8 col-md-10">
                        <div class="form-body-content">
                          <div class="card-elem">
                            <div class="registration">
                              <h3 itemprop="alternativeHeadline" class="registration__title">Data Ibu</h3>
                              <div class="row gutter-16 col-mb-0">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="namaIbu">Nama</label>
                                    <validation-provider
                                      #default="{ errors }"
                                      rules="required|max:100|min:3|alpha_spaces"
                                      name="mname"
                                    >
                                      <input
                                        id="namaIbu"
                                        type="text"
                                        placeholder="e.g. Ahmad Wijayanto"
                                        class="form-control"
                                        v-model="input.mname"
                                      />
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <validation-provider
                                    #default="{errors}"
                                    name="memail"
                                    rules="required|email"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="emailIbu">Email</label>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"
                                            ><at-sign-icon></at-sign-icon
                                          ></span>
                                        </div>

                                        <input
                                          type="email"
                                          name="email"
                                          id="emailIbu"
                                          class="form-control"
                                          v-model="input.memail"
                                          placeholder="e.g. nama@mail.com"
                                        />
                                      </div>
                                      <small class="form-text text-muted">*Masukkan email aktif karena digunakan untuk info lebih lanjut</small>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label itemprop="name" for="tempatLahirIbu">Tempat Lahir</label>
                                    <validation-provider
                                      #default="{errors}"
                                      name="mplace_of_birth"
                                      rules="required|alpha_spaces"
                                    >
                                      <input
                                        id="tempatLahirIbu"
                                        type="text"
                                        placeholder="e.g. Bogor"
                                        class="form-control"
                                        v-model="input.mplace_of_birth"
                                      />
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <validation-provider
                                    #default="{errors}"
                                    rules="required"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="tglLahirIbu">Tanggal Lahir</label>
                                      <div class="input-group">
                                        <date-picker
                                          id="tglLahirIbu"
                                          placeholder="DD-MM-YYYY"
                                          v-model="input.mdate_of_birth"
                                          type="date"
                                          format="DD-MM-YYYY"
                                          input-class="form-control"
                                          :default-value="new Date((cluster.year - 22), (cluster.month - 1), cluster.day).toString()"
                                          :disabled-date="(date) => date > new Date((cluster.year - 22), (cluster.month - 1), cluster.day)"
                                        ></date-picker>
                                        <div class="input-group-append">
                                          <span class="input-group-text"
                                            ><calendar-icon></calendar-icon
                                          ></span>
                                        </div>
                                      </div>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <validation-provider
                                      #default="{errors}"
                                      name="maddress"
                                      rules="required"
                                    >
                                      <label itemprop="name" for="alamatIbu">Alamat</label>
                                      <textarea
                                        name="alamat"
                                        id="alamatIbu"
                                        placeholder="e.g. Jl. Pahlawan No 50B, Tangerang Selatan"
                                        rows="5"
                                        class="form-control"
                                        v-model="input.maddress"
                                      ></textarea>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <validation-provider
                                    #default="{errors}"
                                    rules="required|numeric"
                                    name="mphone"
                                  >
                                    <div class="form-group">
                                      <label itemprop="name" for="noHpIbu">No. Handphone</label>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text"
                                            ><phone-icon></phone-icon
                                          ></span>
                                        </div>
                                        <input
                                          type="tel"
                                          maxlength="13"
                                          name="noHp"
                                          id="noHpIbu"
                                          class="form-control"
                                          v-model="input.mphone"
                                        />
                                      </div>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <validation-provider
                                      #default="{errors}"
                                      rules="required"
                                      name="mreligion"
                                    >
                                      <label itemprop="name" for="agamaIbu">Agama</label>
                                      <select
                                        name="agama"
                                        id="agamaIbu"
                                        class="custom-select form-control"
                                        v-model="input.mreligion"
                                      >
                                        <option value="" selected hidden
                                          >-- Pilih Agama --</option
                                        >
                                        <option value="Islam">Islam</option>
                                        <option value="Kristen">Kristen</option>
                                        <option value="Konghucu"
                                          >Konghucu</option
                                        >
                                        <option value="Buddha">Buddha</option>
                                        <option value="Lainnya">Lainnya</option>
                                      </select>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <validation-provider
                                      #default="{errors}"
                                      rules="required"
                                      name="mlast_education"
                                    >
                                      <label itemprop="name" for="pendidikanIbu">Pendidikan Terakhir</label>
                                      <select
                                        name="pendidikan"
                                        id="pendidikanIbu"
                                        class="custom-select form-control"
                                        v-model="input.mlast_education"
                                      >
                                        <option value="" selected hidden
                                          >-- Pilih Pendidikan Terakhir
                                          --</option
                                        >
                                        <option value="S3">S3</option>
                                        <option value="S2">S2</option>
                                        <option value="S1">S1</option>
                                        <option value="SMA"
                                          >SMA/sederajat</option
                                        >
                                        <option value="SMP">SMP</option>
                                        <option value="SD">SD</option>
                                        <option value="Lainnya">Lainnya</option>
                                      </select>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="form-group">
                                    <validation-provider
                                      #default="{errors}"
                                      rules="required"
                                      name="mjob"
                                    >
                                      <label itemprop="name" for="pekerjaanIbu">Pekerjaan</label>
                                      <select
                                        name="pekerjaan"
                                        id="pekerjaanIbu"
                                        class="custom-select form-control"
                                        v-model="input.mjob"
                                      >
                                        <option value="" selected hidden
                                          >-- Pilih Pekerjaan --</option
                                        >
                                        <option value="Wiraswasta"
                                          >Wiraswasta</option
                                        >
                                        <option value="Karyawan Swasta"
                                          >Karyawan Swasta</option
                                        >
                                        <option value="Karyawan Negeri"
                                          >Karyawan Negeri</option
                                        >
                                        <option value="Pengusaha"
                                          >Pengusaha</option
                                        >
                                        <option value="Lainnya">Lainnya</option>
                                      </select>
                                      <span class="text-danger">{{
                                        errors[0]
                                      }}</span>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group mb-0">
                                    <label itemprop="name" for="alamatKantorIbu"
                                      >Alamat Kantor (opsional)</label
                                    >
                                    <textarea
                                      name="alamatKantor"
                                      id="alamatKantorIbu"
                                      rows="6"
                                      class="form-control"
                                      v-model="input.mjob_address"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-elem">
                            <div class="registration">
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheckAyah"
                                  v-model="input.addFather"
                                />
                                <label
                                  itemprop="name"
                                  class="custom-control-label"
                                  for="customCheckAyah"
                                  >Tambahkan Data Ayah?</label
                                >
                              </div>
                              <div
                                class="mt-3 d-none"
                                id="dataAyah"
                                v-if="input.addFather"
                              >
                                <h3 itemprop="alternativeHeadline" class="registration__title">
                                  Data Ayah
                                </h3>
                                <div class="row gutter-16 col-mb-0">
                                  <div class="col-md-8">
                                    <div class="form-group">
                                      <label itemprop="name" for="namaAyah">Nama</label>
                                      <validation-provider
                                        #default="{errors}"
                                        :rules="
                                          input.addFather
                                            ? 'required|max:100|min:3|alpha_spaces'
                                            : ''
                                        "
                                        name="fname"
                                      >
                                        <input
                                          id="namaAyah"
                                          type="text"
                                          placeholder="e.g. Ahmad Wijayanto"
                                          class="form-control"
                                          v-model="input.fname"
                                        />
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        name="fplace_of_birth"
                                        :rules="
                                          input.addFather
                                            ? 'required|alpha_spaces'
                                            : ''
                                        "
                                      >
                                        <label itemprop="name" for="tempatLahirAyah">Tempat Lahir</label>
                                        <input
                                          id="tempatLahirAyah"
                                          type="text"
                                          placeholder="e.g. Bogor"
                                          class="form-control"
                                          v-model="input.fplace_of_birth"
                                        />
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <validation-provider
                                      #default="{errors}"
                                      name="fdate_of_birth"
                                      :rules="input.addFather ? 'required' : ''"
                                    >
                                      <div class="form-group">
                                        <label itemprop="name" for="tglLahirAyah">Tanggal Lahir</label>
                                        <div class="input-group">
                                          <date-picker
                                            id="tglLahirAyah"
                                            placeholder="DD-MM-YYYY"
                                            v-model="input.fdate_of_birth"
                                            type="date"
                                            format="DD-MM-YYYY"
                                            input-class="form-control"
                                            :default-value="new Date((cluster.year - 22), (cluster.month - 1), cluster.day).toString()"
                                            :disabled-date="(date) => date > new Date((cluster.year - 22), (cluster.month - 1), cluster.day)"
                                          ></date-picker>
                                          <div class="input-group-append">
                                            <span class="input-group-text"
                                              ><calendar-icon></calendar-icon
                                            ></span>
                                          </div>
                                        </div>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </div>
                                    </validation-provider>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        name="faddress"
                                        :rules="
                                          input.addFather ? 'required' : ''
                                        "
                                      >
                                        <label itemprop="name" for="alamatAyah">Alamat</label>
                                        <textarea
                                          name="alamat"
                                          id="alamatAyah"
                                          placeholder="e.g. Jl. Pahlawan No 50B, Tangerang Selatan"
                                          rows="5"
                                          class="form-control"
                                          v-model="input.faddress"
                                        ></textarea>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <validation-provider
                                      #default="{errors}"
                                      name="fphone"
                                      :rules="
                                        input.addFather
                                          ? 'required|numeric'
                                          : ''
                                      "
                                    >
                                      <div class="form-group">
                                        <label itemprop="name" for="noHpAyah">No. Handphone</label>
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"
                                              ><phone-icon></phone-icon
                                            ></span>
                                          </div>
                                          <input
                                            type="tel"
                                            maxlength="13"
                                            name="noHp"
                                            id="noHpAyah"
                                            class="form-control"
                                            v-model="input.fphone"
                                          />
                                        </div>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </div>
                                    </validation-provider>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        :rules="
                                          input.addFather ? 'required' : ''
                                        "
                                        name="freligion"
                                      >
                                        <label itemprop="name" for="agamaAyah">Agama</label>
                                        <select
                                          name="agama"
                                          id="agamaAyah"
                                          class="custom-select form-control"
                                          v-model="input.freligion"
                                        >
                                          <option value="" selected hidden
                                            >-- Pilih Agama --</option
                                          >
                                          <option value="Islam">Islam</option>
                                          <option value="Kristen"
                                            >Kristen</option
                                          >
                                          <option value="Konghucu"
                                            >Konghucu</option
                                          >
                                          <option value="Buddha">Buddha</option>
                                          <option value="Lainnya"
                                            >Lainnya</option
                                          >
                                        </select>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label itemprop="name" for="pendidikanAyah">Pendidikan Terakhir</label>
                                      <validation-provider
                                        #default="{errors}"
                                        :rules="
                                          input.addFather ? 'required' : ''
                                        "
                                        name="flast_education"
                                      >
                                        <select
                                          name="pendidikan"
                                          id="pendidikanAyah"
                                          class="custom-select form-control"
                                          v-model="input.flast_education"
                                        >
                                          <option value="" selected hidden
                                            >-- Pilih Pendidikan Terakhir
                                            --</option
                                          >
                                          <option value="S3">S3</option>
                                          <option value="S2">S2</option>
                                          <option value="S1">S1</option>
                                          <option value="SMA"
                                            >SMA/sederajat</option
                                          >
                                          <option value="SMP">SMP</option>
                                          <option value="SD">SD</option>
                                          <option value="Lainnya"
                                            >Lainnya</option
                                          >
                                        </select>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        :rules="
                                          input.addFather ? 'required' : ''
                                        "
                                      >
                                        <label itemprop="name" for="pekerjaanAyah">Pekerjaan</label>
                                        <select
                                          name="pekerjaan"
                                          id="pekerjaanAyah"
                                          class="custom-select form-control"
                                          v-model="input.fjob"
                                        >
                                          <option value="" selected hidden
                                            >-- Pilih Pekerjaan --</option
                                          >
                                          <option value="Wiraswasta"
                                            >Wiraswasta</option
                                          >
                                          <option value="Karyawan Swasta"
                                            >Karyawan Swasta</option
                                          >
                                          <option value="Karyawan Negeri"
                                            >Karyawan Negeri</option
                                          >
                                          <option value="Pengusaha"
                                            >Pengusaha</option
                                          >
                                          <option value="Lainnya"
                                            >Lainnya</option
                                          >
                                        </select>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group mb-0">
                                      <label itemprop="name" for="alamatKantorAyah"
                                        >Alamat Kantor (opsional)</label
                                      >
                                      <textarea
                                        name="alamatKantor"
                                        id="alamatKantorAyah"
                                        rows="6"
                                        class="form-control"
                                        v-model="input.fjob_address"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-elem">
                            <div class="registration">
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheckWali"
                                  v-model="input.addPic"
                                />
                                <label
                                  itemprop="name"
                                  class="custom-control-label"
                                  for="customCheckWali"
                                  >Tambahkan Data Wali?</label
                                >
                              </div>
                              <div class="mt-3 d-none" id="dataWali">
                                <h3 itemprop="alternativeHeadline" class="registration__title">Data Wali</h3>
                                <div class="row gutter-16 col-mb-0">
                                  <div class="col-md-8">
                                    <div class="form-group">
                                      <label itemprop="name" for="namaWali">Nama</label>
                                      <validation-provider
                                        #default="{errors}"
                                        name="pname"
                                        :rules="
                                          input.addPic
                                            ? 'required|max:100|min:3|alpha_spaces'
                                            : ''
                                        "
                                      >
                                        <input
                                          id="namaWali"
                                          type="text"
                                          placeholder="e.g. Ahmad Wijayanto"
                                          class="form-control"
                                          v-model="input.pname"
                                        />
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label itemprop="name" for="tempatLahirWali">Tempat Lahir</label>
                                      <validation-provider
                                        #default="{errors}"
                                        name="pplace_of_birth"
                                        :rules="
                                          input.addPic
                                            ? 'required|alpha_spaces'
                                            : ''
                                        "
                                      >
                                        <input
                                          id="tempatLahirWali"
                                          type="text"
                                          placeholder="e.g. Bogor"
                                          class="form-control"
                                          v-model="input.pplace_of_birth"
                                        />
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <validation-provider
                                      #default="{errors}"
                                      name="pdate_of_birth"
                                      :rules="input.addPic ? 'required' : ''"
                                    >
                                      <div class="form-group">
                                        <label itemprop="name" for="tglLahirWali">Tanggal Lahir</label>
                                        <div class="input-group">
                                          <date-picker
                                            id="tglLahirWali"
                                            placeholder="DD-MM-YYYY"
                                            v-model="input.pdate_of_birth"
                                            type="date"
                                            format="DD-MM-YYYY"
                                            input-class="form-control"
                                            :default-value="new Date((cluster.year - 22), (cluster.month - 1), cluster.day).toString()"
                                            :disabled-date="(date) => date > new Date((cluster.year - 22), (cluster.month - 1), cluster.day)"
                                          ></date-picker>
                                          <div class="input-group-append">
                                            <span class="input-group-text"
                                              ><calendar-icon></calendar-icon
                                            ></span>
                                          </div>
                                        </div>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </div>
                                    </validation-provider>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <label itemprop="name" for="alamatWali">Alamat</label>
                                      <validation-provider
                                        #default="{errors}"
                                        name="paddress"
                                        :rules="input.addPic ? 'required' : ''"
                                      >
                                        <textarea
                                          name="alamat"
                                          id="alamatWali"
                                          rows="5"
                                          placeholder="e.g. Jl. Pahlawan No 50B, Tangerang Selatan"
                                          class="form-control"
                                          v-model="input.paddress"
                                        ></textarea>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <validation-provider
                                      #default="{errors}"
                                      name="pphone"
                                      :rules="
                                        input.addPic ? 'required|numeric' : ''
                                      "
                                    >
                                      <div class="form-group">
                                        <label itemprop="name" for="noHpWali">No. Handphone</label>
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text"
                                              ><phone-icon></phone-icon
                                            ></span>
                                          </div>
                                          <input
                                            type="tel"
                                            maxlength="13"
                                            name="noHp"
                                            id="noHpWali"
                                            class="form-control"
                                            v-model="input.pphone"
                                          />
                                        </div>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </div>
                                    </validation-provider>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        name="preligion"
                                        :rules="input.addPic ? 'required' : ''"
                                      >
                                        <label itemprop="name" for="agamaWali">Agama</label>
                                        <select
                                          name="agama"
                                          id="agamaWali"
                                          class="custom-select form-control"
                                          v-model="input.preligion"
                                        >
                                          <option value="" selected hidden
                                            >-- Pilih Agama --</option
                                          >
                                          <option value="Islam">Islam</option>
                                          <option value="kristen"
                                            >Kristen</option
                                          >
                                          <option value="konghucu"
                                            >Konghucu</option
                                          >
                                          <option value="Buddha">Buddha</option>
                                        </select>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        name="plast_education"
                                        :rules="input.addPic ? 'required' : ''"
                                      >
                                        <label itemprop="name" for="pendidikanWali"
                                          >Pendidikan Terakhir</label
                                        >
                                        <select
                                          name="pendidikan"
                                          id="pendidikanWali"
                                          class="custom-select form-control"
                                          v-model="input.plast_education"
                                        >
                                          <option value="" selected hidden
                                            >-- Pilih Pendidikan Terakhir
                                            --</option
                                          >
                                          <option value="S3">S3</option>
                                          <option value="S2">S2</option>
                                          <option value="S1">S1</option>
                                          <option value="SMA"
                                            >SMA/sederajat</option
                                          >
                                          <option value="SMP">SMP</option>
                                          <option value="SD">SD</option>
                                          <option value="Lainnya"
                                            >Lainnya</option
                                          >
                                        </select>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <div class="form-group">
                                      <validation-provider
                                        #default="{errors}"
                                        name="pjob"
                                        :rules="input.addPic ? 'required' : ''"
                                      >
                                        <label itemprop="name" for="pekerjaanWali">Pekerjaan</label>
                                        <select
                                          name="pekerjaan"
                                          id="pekerjaanWali"
                                          class="custom-select form-control"
                                          v-model="input.pjob"
                                        >
                                          <option value="" selected hidden
                                            >-- Pilih Pekerjaan --</option
                                          >
                                          <option value="Wiraswasta"
                                            >Wiraswasta</option
                                          >
                                          <option value="Karyawan Swasta"
                                            >Karyawan Swasta</option
                                          >
                                          <option value="Karyawan Negeri"
                                            >Karyawan Negeri</option
                                          >
                                          <option value="Pengusaha"
                                            >Pengusaha</option
                                          >
                                          <option value="Lainnya"
                                            >Lainnya</option
                                          >
                                        </select>
                                        <span class="text-danger">{{
                                          errors[0]
                                        }}</span>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group mb-0">
                                      <label itemprop="name" for="alamatKantorWali"
                                        >Alamat Kantor (opsional)</label
                                      >
                                      <textarea
                                        name="alamatKantor"
                                        id="alamatKantorWali"
                                        rows="6"
                                        class="form-control"
                                        v-model="input.pjob_address"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-elem">
                            <div class="registration">
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheckTerms"
                                  v-model="input.aggreTerms"
                                />
                                <label
                                  itemprop="name"
                                  class="custom-control-label"
                                  for="customCheckTerms"
                                  >Saya setuju dengan
                                  <router-link
                                    :to="{ name: 'Toc' }"
                                    class="underline-link"
                                    target="_blank"
                                  >
                                    Syarat dan Ketentuan</router-link
                                  >
                                  yang telah dibuat oleh pihak SD Islam
                                  Al Hidayah</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex flex-column-reverse flex-md-row justify-content-between text-center"
                        >
                          <a
                            href="#"
                            class="tab-linker"
                            rel="2"
                            ><i class="icon-line-arrow-left"></i> Sebelumnya</a
                          >
                          <button
                            class="cta cta--primary mb-3 mb-md-0"
                            type="submit"
                            :class="input.aggreTerms ? '' : 'cta--disabled'"
                            v-if="!isLoading"
                          >
                            Submit
                          </button>
                          <button
                            v-else
                            class="cta cta--primary btn btn-loading"
                            type="button"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                              style="width: 2rem; height: 2rem;"
                            ></span>
                            <span class="sr-only">Loading...</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<style>
.mx-datepicker{
  display: block;
  width: none;
  flex: 1;
}
.mx-datepicker input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.mx-datepicker i {
  display: none;
}
.stepper-point {
  pointer-events: none;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: default;
}

.uploader__btn {
  width: 13ch;
}

.spinner-border {
  vertical-align: middle;
}
</style>
