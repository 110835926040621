const BoConfig = {
  sidebar: [{
      name: "Main",
      divider: true
    },
    {
      name: "Dashboard",
      link: "BoDashboard",
      icon: "icon-speedometer"
    },
    {
      name: "Pages",
      divider: true
    },
    {
      name: "Home",
      link: "BoHome",
      icon: "ti-home"
    },
    {
      name: "Tentang Kami",
      icon: "icon-question",
      childs: [{
          name: "SDI Al Hidayah",
          link: "BoAboutSdi"
        },
        {
          name: "Yayasan Al Hidayah",
          link: "BoAboutYayasan"
        },
      ],
    },
    {
      name: "Berita & Artikel",
      icon: "icon-doc",
      childs: [{
          name: "Kategori",
          link: "BoNewsCategory"
        },
        {
          name: "Post",
          link: "BoNews"
        },
      ],
    },
    {
      name: "Akademik",
      icon: "ti-book",
      childs: [{
          name: "Kalender Akademik",
          link: "BoCalendar"
        },
        {
          name: "Kurikulum",
          link: "BoCurriculum"
        },
      ],
    },
    {
      name: "Galeri",
      icon: "icon-picture",
      childs: [{
          name: "Kategori",
          link: "BoGalleryCategory"
        },
        {
          name: "Galeri",
          link: "BoGallery"
        },
      ],
    },
    {
      name: "Lembaga",
      icon: "ti-layout-list-thumb",
      link: "BoInstitution",
    },
    {
      name: "Prestasi",
      icon: "ti-medall",
      link: "BoPrestasi"
    },
    {
      name: "Fasilitas",
      icon: "ti-star",
      link: "BoFasilitas"
    },
    {
      name: "Testimoni",
      icon: "ti-comment-alt",
      link: "BoTestimoni"
    },
    {
      name: "Kontak",
      link: "BoContact",
      icon: "icon-phone"
    },
    {
      name: "Registration",
      divider: true
    },
    {
      name: "PPDB",
      link: "BoPPDB",
      icon: "ti-receipt"
    },
    {
      name: "Settings",
      divider: true
    },
    {
      name: "Settings",
      icon: "icon-settings",
      childs: [{
          name: "Legal",
          link: "Legal"
        },
        {
          name: "Static SEO",
          link: "StaticSeo"
        },
        {
          name: "Web Settings",
          link: "WebSettings"
        },
        {
          name: "Template",
          link: "MrTemplate"
        },
      ],
    },
    {
      name: "Manajemen User",
      icon: "icon-people",
      childs: [{
          name: "Level User",
          link: "BoUserLevel"
        },
        {
          name: "Akses User",
          link: "BoUsers"
        },
      ],
    },
  ],
};
export default BoConfig;